@use "node_modules/@angular/material/core/theming/theming";
@use "node_modules/@angular/material/legacy-core/core-theme";
@use "node_modules/@angular/material/legacy-button/button-theme";
@use "node_modules/@angular/material/legacy-card/card-theme";
@use "node_modules/@angular/material/legacy-progress-bar/progress-bar-theme";
@use "node_modules/@angular/material/legacy-progress-spinner/progress-spinner-theme";
@use "node_modules/@angular/material/legacy-tooltip/tooltip-theme";
@use "node_modules/@angular/material/legacy-input/input-theme";
@use "node_modules/@angular/material/legacy-form-field/form-field-theme";
@use "node_modules/@angular/material/legacy-checkbox/checkbox-theme";
@use "node_modules/@angular/material/legacy-select/select-theme";
@use "node_modules/@angular/material/legacy-autocomplete/autocomplete-theme";
@use "node_modules/@angular/material/legacy-dialog/dialog-theme";
@use "node_modules/@angular/material/legacy-chips/chips-theme";
@use "node_modules/@angular/material/legacy-slide-toggle/slide-toggle-theme";
@use "node_modules/@angular/material/legacy-radio/radio-theme";
@use "node_modules/@angular/material/legacy-slider/slider-theme";
@use "node_modules/@angular/material/legacy-menu/menu-theme";
@use "node_modules/@angular/material/legacy-list/list-theme";
@use "node_modules/@angular/material/legacy-paginator/paginator-theme";
@use "node_modules/@angular/material/legacy-tabs/tabs-theme";
@use "node_modules/@angular/material/legacy-snack-bar/snack-bar-theme";
@use "node_modules/@angular/material/legacy-table/table-theme";
@use "node_modules/@angular/material/badge/badge-theme";
@use "node_modules/@angular/material/bottom-sheet/bottom-sheet-theme";
@use "node_modules/@angular/material/button-toggle/button-toggle-theme";
@use "node_modules/@angular/material/datepicker/datepicker-theme";
@use "node_modules/@angular/material/divider/divider-theme";
@use "node_modules/@angular/material/expansion/expansion-theme";
@use "node_modules/@angular/material/grid-list/grid-list-theme";
@use "node_modules/@angular/material/icon/icon-theme";
@use "node_modules/@angular/material/sidenav/sidenav-theme";
@use "node_modules/@angular/material/stepper/stepper-theme";
@use "node_modules/@angular/material/sort/sort-theme";
@use "node_modules/@angular/material/toolbar/toolbar-theme";
@use "node_modules/@angular/material/tree/tree-theme";
@use "@angular/material" as mat;
@use "sass:map";

$fallback-color: #001a33;

$fallback-palette: (
  500: $fallback-color,
  100: $fallback-color,
  700: $fallback-color,
  contrast: (
    500: #fff,
    100: #fff,
    700: #fff,
  ),
);

$fallback-primary: mat.define-palette($fallback-palette);
$fallback-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

$fallbackTheme: mat.define-light-theme(
  (
    color: (
      primary: $fallback-primary,
      accent: $fallback-accent,
    ),
    density: 0,
  )
);

// Create a theme.
/// @deprecated Use `mat.all-component-themes` instead. See https://material.angular.io/guide/mdc-migration for information about migrating.
/// @breaking-change 17.0.0
@mixin all-legacy-component-themes($theme-or-color-config) {
  $dedupe-key: "angular-material-legacy-theme";
  @include theming.private-check-duplicate-theme-styles(
    $theme-or-color-config,
    $dedupe-key
  ) {
    // Legacy components.
    @include button-theme.theme($fallbackTheme);
    @include core-theme.theme($theme-or-color-config);
    @include card-theme.theme($theme-or-color-config);
    @include progress-bar-theme.theme($theme-or-color-config);
    @include progress-spinner-theme.theme($theme-or-color-config);
    @include tooltip-theme.theme($theme-or-color-config);
    @include input-theme.theme($theme-or-color-config);
    @include form-field-theme.theme($theme-or-color-config);
    @include select-theme.theme($theme-or-color-config);
    @include checkbox-theme.theme($theme-or-color-config);
    @include autocomplete-theme.theme($theme-or-color-config);
    @include dialog-theme.theme($theme-or-color-config);
    @include chips-theme.theme($theme-or-color-config);
    @include slide-toggle-theme.theme($theme-or-color-config);
    @include radio-theme.theme($theme-or-color-config);
    @include slider-theme.theme($theme-or-color-config);
    @include menu-theme.theme($theme-or-color-config);
    @include list-theme.theme($theme-or-color-config);
    @include paginator-theme.theme($theme-or-color-config);
    @include tabs-theme.theme($theme-or-color-config);
    @include snack-bar-theme.theme($theme-or-color-config);
    @include table-theme.theme($theme-or-color-config);

    // Components without MDC versions.
    @include badge-theme.theme($theme-or-color-config);
    @include bottom-sheet-theme.theme($theme-or-color-config);
    @include button-toggle-theme.theme($theme-or-color-config);
    @include datepicker-theme.theme($fallbackTheme);
    @include divider-theme.theme($theme-or-color-config);
    @include expansion-theme.theme($theme-or-color-config);
    @include grid-list-theme.theme($theme-or-color-config);
    @include icon-theme.theme($theme-or-color-config);
    @include sidenav-theme.theme($theme-or-color-config);
    @include stepper-theme.theme($theme-or-color-config);
    @include sort-theme.theme($theme-or-color-config);
    @include toolbar-theme.theme($theme-or-color-config);
    @include tree-theme.theme($theme-or-color-config);
  }

  .mat-raised-button.mat-primary {
    background-color: map.get(map.get($theme-or-color-config, primary), 500);
  }
}

/// @deprecated Use `mat.all-component-themes` instead. See https://material.angular.io/guide/mdc-migration for information about migrating.
/// @breaking-change 17.0.0
@mixin angular-material-legacy-theme($theme-or-color-config) {
  @include all-legacy-component-themes($theme-or-color-config);
}
