@import "./shared.scss";
@import "./compInput.scss";
@import "./fonts.scss";

.gradient {
  background: $primary-gradient;
}

.accent {
  height: 5px;
}

.scroll-container {
  height: 100%;
  position: relative;
}

.document-overlay app-document .placeholder-inserted:is(p, div) {
  display: block !important;
  white-space: unset !important;
}

app-document .sfPageBreak {
  display: block;
  border: 1px dotted #e0e0e0;

  &::before {
    content: "-- PAGE BREAK --";
    color: #e0e0e0;
    text-align: center;
    width: 100%;
    display: block;
    font-size: 0.5rem;
  }
}

beta-hint {
  display: inline-flex;
  padding: 10px;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  color: white;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  height: 15px;
  font-size: 0.8rem;
  margin: 10px;

  &::after {
    content: "BETA";
  }
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: -1;
  background: $client-color;
  width: 100%;
  transition: transform 0.4s;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.4;
    filter: blur(2px);
  }
}

.mat-raised-button:focus,
.mat-button:focus {
  outline: 2px solid $client-color;
  outline-offset: 5px;
  border-radius: 5px;
}

.mat-icon-button:focus {
  outline: 2px solid $client-color;
  outline-offset: 5px;
}

a:not(.mat-button) {
  color: $search-color;
  text-decoration: underline;

  .mat-icon {
    font-size: 12px;
    width: 12px;
    height: 12px;
    line-height: 12px;
    margin-right: 10px;
  }
}

.text-light {
  font-weight: 200;
}

.text-bold {
  font-weight: 700;
}

::ng-deep {
  .mat-form-field-outline-start,
  .mat-form-field-outline-gap,
  .mat-form-field-outline-end {
    background-color: white !important;
  }

  .mat-form-field-appearance-fill .mat-form-field-flex {
    background-color: white !important;
  }
}

// prevent the tooltip from ellipsing the text - and allow it to break long words if needed
.mat-tooltip {
  overflow-wrap: break-word !important;
}

span,
p,
div {
  font-family: "Poppins", Arial, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", Arial, sans-serif !important;
}

::ng-deep .jodit {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  span,
  p,
  div {
    font: Arial;
  }
}

#main-splash-screen {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  height: 100dvh;
  width: 100vw;
  background-color: white;
  z-index: 99999;
  opacity: 1;
  transition: background-color 1s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.loaded {
    animation: none;
    background-color: rgba(0, 0, 0, 0);
    pointer-events: none;

    & > img {
      opacity: 0 !important;
    }

    .spinner {
      opacity: 0;
    }
  }

  & > img {
    width: 320px;
    max-width: 90vw;
    max-height: 320px;
    margin-bottom: 20px;
    opacity: 0;
    object-fit: contain;
    transform: scale(0.7);
    transition: opacity 0.5s transform 0.5s;

    &.loaded {
      opacity: 1;
      transform: scale(1);
      animation: logo-pop-up 500ms;
    }

    @keyframes logo-pop-up {
      from {
        opacity: 0;
        transform: scale(0.7);
      }
      to {
        opacity: 1;
        transform: scale(1);
      }
    }
  }

  .spinner {
    animation: rotator 1.6s linear infinite;

    circle {
      animation: dash 1.6s ease-in-out infinite;
      stroke: $client-color;
      stroke-dasharray: 140;
      stroke-dashoffset: 0;
      transform-origin: center;
    }
  }

  @keyframes rotator {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(270deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dashoffset: 140;
    }

    50% {
      stroke-dashoffset: 35;
      transform: rotate(135deg);
    }

    100% {
      stroke-dashoffset: 140;
      transform: rotate(450deg);
    }
  }
}

placeholder {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0.8rem;
  border-radius: 30px;
  font-weight: 300;
  cursor: pointer;
  position: relative;
  text-indent: 0;
  white-space: nowrap;

  &.not-found {
    color: red;
    background: rgba(255, 0, 0, 0.13);
    border: 1px solid red;

    &:before {
      content: "!";
      margin-right: 0.4rem;
      color: mix(red, white, 80%);
    }

    &:hover {
      background: mix(red, white, 30%);
    }
  }

  icon {
    font-family: "Material Icons";
    font-weight: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased;
    font-size: 0.8rem;
    display: none;
    transform: translate(-5px, 2px);
    width: 1rem;
    color: var(--base-color);
    user-select: none;
  }

  .subpath {
    margin-left: 5px;
    margin-top: -0.1rem;
    display: inline-block;
    padding-left: 5px;
    margin-right: -0.8rem;
    padding-right: 5px;
    pointer-events: none;
    opacity: 0.9;

    &:before {
      content: ">";
      font-weight: bold;
      margin-right: 6px;
    }

    .subpath {
      background: none;
      margin-right: 0;
    }
  }

  &.found {
    font-weight: 450;
    color: $client-color;
    // border: 1px solid $client-color;
    box-sizing: border-box;

    icon {
      display: inline-block;
    }

    &::after {
      content: "";
      background: $client-color;
      opacity: 0.1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 30px;
    }

    &:hover {
      background: hsl(
        var(--client-color-h),
        var(--client-color-s),
        calc(1.5 * var(--client-color-l))
      );
    }

    &.warning {
      background: $warn-color;
      border-color: #a87f0d;
      color: #4b3a0c;

      icon {
        color: #4b3a0c;
      }
    }
  }
}

.eb-dynamic-button {
  display: inline-block;
  border-radius: 5px;
  background: $client-color;
  color: white;
  padding: 5px 20px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &.disabled {
    background: #efefef;
    pointer-events: none;
  }
}

.slide-in-dialog {
  width: 90vw !important;
  max-width: 100vw !important;
  height: 100%;

  @media only screen and (max-width: 750px) {
    width: 100vw !important;
  }
}

/* Phones & Tablets  */
@media (max-width: 1024px) {
  h1, h2, h3, h4, h5, h6,
  button,
  a,
  nav,
  header,
  footer,
  figure,
  img,
  input[type="button"], input[type="submit"],
  label,
  select {
    user-select: none;
  }
}
