.comparison-input {
  display: flex;
  flex-direction: row;
  height: 30px;
  border-radius: 0.2rem;
  overflow: hidden;

  .desc-icon {
    background: red;
    color: white;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    flex-shrink: 0;

    &.found {
      background: $client-color;
    }
  }

  input {
    border: none;
    background: #ffffff;
    outline: 0 !important;
    margin: 0;
    padding: 0.5rem;
    text-align: center;


    &:hover {
      opacity: 0.9;
    }

    &:nth-of-type(1) {
      background: mix(red, transparent, 10%);

      &.found {
        // background: mix($client-color, transparent, 10%);
        background-color: hsla(var(--client-color-h), var(--client-color-s), var(--client-color-l), 0.1);
      }
    }

    &:nth-of-type(2) {
      background: mix(red, transparent, 15%);

      &.found {
        background: #efefef;
      }
      width: 48px;
    }

    &:nth-of-type(3) {
      background: mix(red, transparent, 20%);

      &.found {
        // background: mix($search-color, transparent, 10%);
        background: hsla(var(--search-color-h),var(--search-color-s),var(--search-color-l), 0.1)
      }
    }
  }
}

.cond-input-dropdown-menu {
  position: fixed;
  top: 0;
  left: 0;
  background: white;
  box-shadow: 2px 2px 4px 0px rgba(0,0,0,0.4);
  padding: 0.3rem 0rem;
  display: flex;
  flex-direction: column;
  z-index: 100000000;

  &> div {
    color: #3a3a3a;
    padding: 0.5rem 1rem;
    cursor: pointer;
    user-select: none;

    &:hover {
      background: #efefef;
    }
  }
}
