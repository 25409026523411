// Custom Theming for Angular Material
@use "@angular/material" as mat;
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

@use "./shared/styles/material-style.scss" as style_fix;

@import "jodit-pro/build/jodit.css";

@import "./shared/styles/shared.scss";

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

$adv-palette: (
  500: $client-color,
  100: $client-color,
  700: $client-color,
  contrast: (
    500: #fff,
    100: #fff,
    700: #fff,
  ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$avg-frontend-primary: mat.define-palette($adv-palette);
$avg-frontend-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$avg-frontend-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$avg-frontend-theme: mat.define-light-theme(
  (
    color: (
      primary: $avg-frontend-primary,
      accent: $avg-frontend-accent,
      warn: $avg-frontend-warn,
    ),
    density: 0,
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include style_fix.all-legacy-component-themes($avg-frontend-theme);

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
  width: 100%;
  height: -webkit-fill-available;
  height: fill-available;
  height: 100dvh;
  max-height: -webkit-fill-available;
  max-height: fill-available;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background: #f3f4f5;
}

@import "./shared/styles/global.scss";
